;(function($){

    function Slides() {
   	 	var _ =  this;

   	 	_.contentSlider();
    }
    
    Slides.prototype.contentSlider =  function() {

       var _ = this;

       _.elementPresentationSlider = $('.slider');

      

       _.elementPresentationSlider.slick({
	        fade: true,
	        arrows: false,
	        dots: true,
	        autoplay: true,
	        autoplaySpeed: 4000,
	        pauseOnFocus:false,
	        customPaging : function(slider, i) {
		        var thumb = $(slider.$slides[i]).data();
		        return '<a><div class="dots"><div class="bar"></div></div></a>';
		    },
          	responsive: [
            {
              breakpoint: 990,
                settings: {
                  arrows:false,
                }
            },
            {
              breakpoint: 480,
                settings: {
                  arrows:false,
                }
            }
          ]
  	   });
    };

   	new Slides();

}(jQuery));
 
 
 
 
