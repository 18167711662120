;(function($){

    function Navigation() {
   	 	var _ =  this;

   	 	//Events
        $(document).on("click", ".btn-menu, .btn-close", function(e){
            e.preventDefault();
            _.navInit();
        });

        $('.anchor').click(function() {
            _.navInative();
           
            var hash = this.hash, 
            target   = $(hash);
            target   = target.length ? target : $('[name=' + this.hash.slice(1) +']');

            if (target.length) {
              $('html, body').animate({
                scrollTop: target.offset().top
              });
              return false;
            }            
        });

        $(".btn-top").on("click", function(e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, 'slow');
            return true;
        });

        $(window).scroll(function() {    
            var scroll = $(window).scrollTop();
            var height = $('.banner-event').height() + $('header').height();
            
            if (scroll >= height) {

                $("body").addClass("nav-fixed");
            } else {
                $("body").removeClass("nav-fixed");
            }
        });

        $(document).on("click", ".nav-tab > li > a",  function(e){
            e.preventDefault();
            $(".nav-tab li a").removeClass('active');              
            $(".tabs .tab").addClass('hidden');

            $(this).addClass('active');
            $(document).find($(this).attr('href')).removeClass('hidden');
        });

        $(".other").click(function() {
            $(".main-drawing").show();
        });

        $(".my").click(function() {
            $(".main-drawing").hide();
        });

        $(".creditcard").click(function() {
            $(".main-creditcard").show();
        });

        $(".barcode").click(function() {
            $(".main-creditcard").hide();
        });

        $(".pix").click(function() {
            $(".main-creditcard").hide();
        });
    }

   	Navigation.prototype.navInit =  function() {
        var _ =  this;
        _.navIsActive() ? _.navInative() : _.navActive();
    };

    Navigation.prototype.navIsActive =  function() {
        return $("body").hasClass('navIsActive');
    };

    Navigation.prototype.navActive =  function() {
        $("body").addClass('navIsActive');
    };

    Navigation.prototype.navInative =  function() {
        $("body").removeClass('navIsActive');
    };

   	new Navigation();

}(jQuery));
 
 
 
 
